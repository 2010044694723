<script>
/* eslint-disable */
import DefaultMixin from '../mixin'
import BtnStatusLeilao from './context/BtnStatusLeilao'
import LeilaoInfo from './include/pages/LeilaoInfo'
import BtnImportacaoLeilao from './context/BtnImportacaoLeilao'
import BtnAcoesLeilao from './context/BtnAcoesLeilao'
import BtnLoteamentoLeilao from './context/BtnLoteamentoLeilao'
import ListaLotes from './include/ListaLotes'
import ComitentesParticipantes from './include/pages/ComitentesParticipantes'
import * as StatusLeilao from '../../../domain/leiloes/helpers/LeilaoStatus'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import MenuSuperior from "@/components/leiloes/components/menu/MenuSuperior"
import AlteracaoStatusLeilaoMixin from "components/leiloes/components/include/AlteracaoStatusLeilaoMixin"

export default {
  name: 'Leilao',
  inject: ['page'],
  mixins: [DefaultMixin],
  components: {
  },
  data() {
    return {
      leilao: this.page.leilao
    }
  },
  computed: {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
  },
  meta: {
    title: 'Preparação',
    name: 'Preparação'
  }
}
</script>

<template>
  <div class="page-leilao-container">
    Módulo em processo de atualização, consulte seu gerente de contas para verificar o andamento da ativação.
  </div>
</template>
